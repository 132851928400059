.root
  display: flex
  flex-direction: column
  align-items: center
  width: 100%
  background-image: url("https://raw.githubusercontent.com/NicoToken/NICOToken/main/asset/Nicologo.png")
  background-repeat: no-repeat
  background-position: bottom center
  background-size: 60%
  &.mobile
    padding: 24px 24px 24px 24px
  &:not(.mobile)
    padding: 400px 24px 24px 24px
    gap: 24px

  .header
    display: flex
    flex-direction: column
    align-items: center
    gap: 64px
    margin-bottom: 64px
    .actions
      display: flex
      flex-direction: row
      gap: 16px

  .links
    padding: 32px 32px 0 32px
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between

    .items
      display: flex
      align-items: center
      &:not(.mobile)
        gap: 32px

    .icon
      width: 48px
      height: 48px
      border-radius: 50%
      display: flex
      align-items: center
      justify-content: center
      font-size: 24px

    .socials
      display: flex
      align-items: center
      gap: 24px
      font-size: 24px
